import React from 'react'
import {Container, Row, Col, Nav} from "react-bootstrap";
import {Link} from "gatsby";
import {FaFacebookF, FaInstagram, FaTwitter} from "react-icons/fa";

const Footer = () => (
	<footer className="bg-light p-3 p-md-5">
		<Container>
			<Row className="align-items-start">
				<Col xs="12" md="4" className="mb-4 mb-md-0">
					<div>© <Link to="/" className="link-secondary">Arbitery</Link>. All Rights Reserved</div>
					<p>
						<small>
							A giveaway picker tool that helps you to select, verify, and announce winners for your sweepstakes, giveaways, or
							competitions on Instagram.
						</small>
					</p>
					<div>
						<a href="https://instagram.com/arbitery" className="mr-2" aria-label="Instagram"><FaInstagram size="2rem" /></a>
						<a href="https://facebook.com/arbitery" className="mr-2" aria-label="Facebook"><FaFacebookF size="2rem" /></a>
						<a href="https://twitter.com/arbiteryhq" aria-label="Twitter"><FaTwitter size="2rem" /></a>
					</div>
				</Col>
				<Col xs="12" md="8" className="d-flex justify-content-between text-muted flex-wrap">
					<Nav as="ul" className="flex-column justify-content-start mb-3">
						<Nav.Item as="li" className="mb-3">
							<b>Trust &amp; Legal</b>
						</Nav.Item>
						<Nav.Item as="li">
							<Nav.Link as="a" href="/terms.html">Terms of Service</Nav.Link>
						</Nav.Item>
						<Nav.Item as="li">
							<Nav.Link as="a" href="/privacy.html">Privacy Policy</Nav.Link>
						</Nav.Item>
					</Nav>
					<Nav as="ul" className="flex-column justify-content-start mb-3">
						<Nav.Item as="li" className="mb-3">
							<b>Account</b>
						</Nav.Item>
						<Nav.Item as="li">
							<Nav.Link as="a" href="https://app.arbitery.com/login">Log In</Nav.Link>
						</Nav.Item>
						<Nav.Item as="li">
							<Nav.Link as="a" href="https://app.arbitery.com/sign-up">Sign Up</Nav.Link>
						</Nav.Item>
						<Nav.Item as="li">
							<Nav.Link as="a" href="https://app.arbitery.com/reset-password">Reset Password</Nav.Link>
						</Nav.Item>
						<Nav.Item as="li">
							<Nav.Link as="a" href="https://help.arbitery.com/">Help Center</Nav.Link>
						</Nav.Item>
					</Nav>
					<Nav as="ul" className="flex-column justify-content-start">
						<Nav.Item as="li" className="mb-3">
							<b>Product</b>
						</Nav.Item>
						<Nav.Item as="li">
							<a className="nav-link"
							   href="https://go.crisp.chat/chat/embed/?website_id=f793a958-55b5-4cd0-ab49-a88e01cb8382">Contact Us</a>
						</Nav.Item>
						<Nav.Item as="li">
							<Nav.Link as="a" href="/#features-section">Features</Nav.Link>
						</Nav.Item>
						<Nav.Item as="li">
							<Nav.Link as="a" href="/#pricing-section">Pricing</Nav.Link>
						</Nav.Item>
						<Nav.Item as="li">
							<Nav.Link as="a" href="/#how-it-works-section">How It Works</Nav.Link>
						</Nav.Item>
						<Nav.Item as="li">
							<Nav.Link as="a" href="/#faq">FAQs</Nav.Link>
						</Nav.Item>
						<Nav.Item as="li">
							<Nav.Link as="a" href="/youtube/">YouTube Random Comment Picker</Nav.Link>
						</Nav.Item>
					</Nav>
				</Col>
			</Row>
		</Container>
	</footer>
);

export default Footer;
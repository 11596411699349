/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import siteScreenshot from '../images/og-main.png'

function Seo({ description, canonical, type, image, lang, meta, title, link, structuredData }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const siteUrl = site.siteMetadata?.siteUrl
  const canonicalUrl = /^https?:\/\//i.test(canonical) ? canonical : `${siteUrl}${canonical}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || defaultTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: defaultTitle,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          property: `og:url`,
          content: canonicalUrl,
        },
        {
          property: `og:image`,
          content: image || `${siteUrl}${siteScreenshot}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      link={[
        { rel: "canonical", href: canonicalUrl },
      ].concat(link)}
      script={structuredData ? [
        { type: 'application/ld+json', innerHTML: JSON.stringify(structuredData)}
      ] : []}
    />
  )
}

Seo.defaultProps = {
  type: `website`,
  lang: `en`,
  meta: [],
  link: [],
  description: ``,
}

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string.isRequired,
  image: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  structuredData: PropTypes.object,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  link: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]),
}

export default Seo

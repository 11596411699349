import React from "react"
import PropTypes from "prop-types"
import {Button, Container, Nav, Navbar} from "react-bootstrap";
import logo from '../images/logo.svg';

const Header = () => {
  const { pathname = '' } = typeof window !== 'undefined' ? window.location : {};
  return (
    <header>
      <Navbar collapseOnSelect expand="md" bg="light">
        <Container>
          <Navbar.Brand href="/" className="d-flex align-items-center">
            <img
              src={logo}
              height="26"
              width="29"
              className="mr-1"
              alt="Arbitery"
            />
            <span className="text-secondary letter-spacing-15">ARBITERY</span>
          </Navbar.Brand>
          {pathname.startsWith('/blog') && <small className="border border-muted rounded px-2 text-muted">Blog</small>}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/#how-it-works-section">How it Works</Nav.Link>
              <Nav.Link href="/#features-section">Features</Nav.Link>
              <Nav.Link className="mr-3" href="/blog/">Blog</Nav.Link>
            </Nav>
            <Button as="a" href="https://app.arbitery.com/login" variant="outline-primary" className="mr-2">Log In</Button>
            <Button as="a" href="https://app.arbitery.com/sign-up" variant="primary">Sign Up</Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
